.join_main {
    height: 100%;
    width: 100vw;
    background-color: rgb(0, 0, 0);
}

.join_head {
    color: rgb(53, 139, 204);
    margin-top: 0%;
    font-family: 'Tiro Gurmukhi', serif;
    font-size: 50px;
    padding-top: 1rem;
    padding-left: 1rem;
}

.join_head2 {
    color: rgb(255, 255, 255);
    font-family: 'Tiro Gurmukhi', serif;
    font-size: 50px;
    padding-left: 1rem;
}



.img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    /* background-color: rgb(31, 31, 34); */

    
}

.img1 {
    text-align: center;
    margin-top: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
    align-items: center;
    justify-content: center;
    border-radius: 8%;
    height: 25rem;
    padding: 1rem;
}

.joinus_title {
    color: rgb(255, 255, 255);
}

.img1:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(255, 255, 255, 0.8) 0px 1px 30px 2.5px;
}

.img1:hover > .img_para  {
    color: #000;
}

.img1:hover > .joinus_title {
    color: #000;
}

.img_para {
    margin: auto;
    width: 50%;
    /* border: 3px solid green; */
    padding: 10px;
    width: 15rem; 
    color: white;
}

.join_img {
    box-shadow: rgba(255, 255, 255, 0.5) 0px 1px 30px 2.5px;
    width: 250px;
    height: auto;;
}


.joinus_links {
    text-decoration: none;
}






@media (max-width: 1420px) {
    .join_img {
        width: 200px;
        height: auto;
    }
    .img_para {
        width: 10rem; 
    }
    .join_head {
        font-size: 30px;
    }
    
    .join_head2 {
        font-size: 30px;
    }
    .img1 {
        margin-top: 2rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
    
  }


@media (max-width: 1000px) {
    .join_img {
        width: 150px;
        height: auto;
    }
    
  }

@media (max-width: 860px) {
    .img_container {
        display: flex;
        flex-direction: column;
    }
    .join_img {
        width: 200px;
        height: auto;
    }
    .join_main {
        height: 100%;
        width: 100%;
        background-color: black;
    }
    .img1 {
        margin-top: 2rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
    
  }