.vid_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}


.vid_container {
  width: 500px;
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgb(235, 238, 241);
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 20px;
}

.vid {
  margin: 3rem;
}

.vid_desc {
  text-align: center;
}

