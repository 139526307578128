.chart_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart_heading {
    color: rgb(53, 139, 204);
    font-size: 60px;
    margin-top: 2rem;
    margin-bottom: 5px;
    font-family: 'Tiro Gurmukhi', serif;
    text-align: center;
    padding: 2rem;
    padding-left: 0;
    padding-right: 0;
    border-radius: 50px;
}



/* .charthead {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.table_map_container{
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-wrap: wrap;  
    margin-top: 3rem;
}


.table_map{
    width: 90%;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.piechart_container {
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-wrap: wrap;
    margin-left: 2rem;
}


.pie_note {
    width:35%;
    background-color:gray;
    color:white;
    border-radius:50px;
    text-align: center;
    padding: 1rem;
    font-size: 15;
}

.pie_note2 {
  /* position: absolute; */
    
    left: 0;
    right: 0;
    
    margin: auto;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    width:35%;
    height: 10%;
    background-color:gray;
    color:white;
    border-radius:50px;
    text-align: center;
    padding: 1rem;
    font-size: 15;
}

/* 
#chart {
    width: 100%;
    hei: 100%;
    min-height: 450px;
  } */


.quote1 {
    margin-top: 6rem;
    margin-bottom: 10rem;
    font-size: 80px;
    color: rgb(53, 139, 204);
    text-align: center;
}

.quote_bad {
    /* text-shadow: rgb(233, 10, 10) 1px 0 10px; */
    text-shadow: 1px 1px 2px red, 0 0 1em red, 0 0 0.5em red;
}


.bar_cart_2 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.vegas_shooting {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}





.container_stat {
    max-width: 2500px;
    margin: 0 auto;
  }
  
  .photos_stat {
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    padding: 0;
  }
  
  .photos_stat img {
    display: block;
    float: left;
    flex: 0 0 auto;
    background-color: #fff;
    margin: 2rem;
    margin-bottom: 3rem;
    width: 100px;

  }


  .donation_links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  
  @media screen and (min-width: 1024px) {
    .photos_stat img {
      width: calc(100%/2);
      height: calc(100%/2);
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .photos_stat img {
      width: calc(100%/3);
      height: calc(100%/3);
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .photos_stat img {
      width: calc(100%/2);
      height: calc(100%/2);
    }
  }
  
  @media screen and (min-width: 321px) and (max-width: 480px) {
    .photos_stat img {
      width: calc(100%);
      height: calc(100%);
    }
  }
  
  @media screen  and (max-width: 320px) {
    .photos_stat img {
      width: 100%;
      height: 100%;
    }
  }