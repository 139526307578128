body {
    overflow-x: hidden;
}

.head_img {
    width: 20%;
    height: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px;
    display: flex;
    justify-content: center;

    align-items: center;
}

.head_img_cont {
    background-color: #738094;
    height: 100vh;
    width: 100vw;
    filter: brightness(0.4);
    position: relative;
    text-align: center;
    color: white;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.head_text {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 35px;
    text-align: center;
    font-family: 'Tiro Gurmukhi', serif;
}

.head_para {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    margin-top: 100px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    width: 100%;
}

.button1 {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 3rem;
    border-style: solid;
    border-radius: 50px;
    text-decoration: none;
}


.button:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border-color: white;
    box-shadow:  0 0 10px #d0c9c9;
  }

.button1:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border-color: white;
    box-shadow:  0 0 10px #d0c9c9;
  }



.second_part {
    height: 100vh;
    width: 100vw;
}

.pistol_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.second_part_text {
    text-align: left;
    margin-left: 2rem;
}

.second_part_text > h1 {
    font-size: 75px;
    color: rgb(53, 139, 204);
    font-family: 'Tiro Gurmukhi', serif;
}

.second_part_text > p {
    font-size:30px;
}


@media screen and (max-width: 1100px) {
    .second_part_text > h1 {
        font-size: 65px;
    }
    .second_part_text > p {
        font-size: 25px;
        width: 100%;
    }
}


@media screen and (max-width: 900px) {
    .second_part_text > h1 {
        font-size: 60px;
    }
    .second_part_text > p {
        font-size: 25px;
        width: 100%;
    }
}


@media screen and (max-width: 800px) {
    .second_part_text > h1 {
        font-size: 50px;
    }
    .second_part_text > p {
        font-size: 15px;
    }
}


@media screen and (max-width: 500px) {
    .second_part_text > h1 {
        font-size: 35px;
        width: 80%;
    }
    .second_part_text > p {
        font-size: 15px;
        width: 100%;
    }
}




.footer {
    background-color: black;
    height: 100vh;
    width: 100vw;
}

.footer_head {
    color: rgb(255, 255, 255);
    font-family: 'Tiro Gurmukhi', serif;
    font-size: 80px;
    padding-top: 5rem;
    text-align: center;
}


.third_col {
    padding-top: 10rem;
    text-align: center;
    background-color: black;
    color: rgb(255, 255, 255);
    font-family: 'Tiro Gurmukhi', serif;
    font-size: 40px;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.button {
    color: rgb(255, 255, 255);
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    border-style: solid;
    border-radius: 50px;
    text-decoration: none;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
}

.footer_container {
    position: relative;
}


.footer2 {
    position: absolute;
    text-align: left;
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    width:'50%';
    bottom: '0';
    margin: 1rem;
    border-left: 2px solid;
}


.top_header_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.top_header_container > a {
    margin-top: 3rem;
}










@media screen and (max-width: 900px) {
    .footer2 {
        font-size: 15px;
    }
    .third_col {
        font-size: 30px;
    }
}


@media screen and (max-width: 550px) {
    .footer2 {
        font-size: 12px;
    }
    .third_col {
        font-size: 23px;
    }
    .button {
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 20px;
    }
    
}

@media screen and (max-width: 355px) {
    .footer2 {
        font-size: 10px;
    }
    .third_col {
        font-size: 23px;
    }
    
}


@media screen and (max-width: 480px) {
    .head_img_cont {
        height: 100vh;
        width: 100vw;
    }
    .head_img {
        width: 50%;
        height: 50%;
    }
}


@media screen and (max-width: 1020px) {
    .head_img_cont {
        height: 100vh;
        width: 100vw;
    }
    .head_img {
        width: 26%;
        height: 26%;
    }
    .head_text {
        font-size: large;
    }
}


@media screen and (min-width: 1020px) {
    .button1 {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(255, 255, 255);
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 5rem;
        border-style: solid;
        border-radius: 50px;
        text-decoration: none;
    }
}

@media screen and (min-width: 1400px) {
    .button1 {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(255, 255, 255);
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 5rem;
        border-style: solid;
        border-radius: 50px;
        text-decoration: none;
        font-size: x-large;
    }
}

@media screen and (max-width: 700px) {
    .head_img_cont {
        height: 100vh;
        width: 100vw;
    }
    .head_img {
        width: 30%;
        height: 30%;
    }
    .head_text {
        font-size: small;
    }
    .head_para {
        font-size: 15px;
    }
    .button1 {
        font-size: x-small;
    }
    .button2 {
        font-size: x-small;
    }
}

@media screen and (max-width: 820px) {
    .button1 {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(255, 255, 255);
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 5px;
        border-style: solid;
        border-radius: 50px;
        text-decoration: none;
    }
}


@media screen and (max-width: 620px) {
    .head_img_cont {
        height: 100vh;
        width: 100vw;
    }
    .head_img {
        width: 45%;
        height: 45%;
    }
}

@media screen and (max-width: 1575px) {
   
   
}

@media screen and (max-width: 1425px) {
    .second_col_text {
       font-size: 30px;
       margin-top: 2rem;
    }
    .second_col {
        width: 25rem;
        height: 25rem;
        background: rgb(249, 249, 249);
        border-radius: 50%;
        margin-top: 2rem;
        float:right;
    }
    .second_col_img {
        width: auto;
        height: 25rem;
        margin-top: 5rem;
     
    }
    
}

@media screen and (max-width: 750px) {
    .second_col_text {
       font-size: 20px;
       margin-top: 2rem;
       width: 25rem;
    }
   
}

@media screen and (max-width: 630px) {
    .second_col {
        width: 10rem;
        height: 10rem;
        background: rgb(249, 249, 249);
        border-radius: 50%;
        margin-top: 2rem;
        float:right;
    }
    .second_col_img {
        width: auto;
        height: 10rem;
        margin-top: 2rem;
     
    }
    
}

@media screen and (max-width: 455px) {
    .second_col {
        visibility: hidden;
        clear: both;
        display: none;
    }
    .second_col_img {
        visibility: hidden;
        clear: both;
        display: none;
    }
    .second_col_text {
        display: flex;
        justify-content: center;
        font-size: xx-large;
    }
    .second_col_para {
        font-size: xx-large;
        text-align: center;
    }
    
}

@media screen and (max-width: 520px) {
    .second_col_para {
       width: 15rem;
       text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .second_col_text {
       font-size: 20px;
       margin-top: 2rem;
       width: 15rem;
    }
    .second_col {
        width: 15rem;
        height: 15rem;
        background: rgb(249, 249, 249);
        border-radius: 50%;
        margin-top: 2rem;
        float: none;
    }
    .second_col_img {
        width: auto;
        height: 15rem;
        margin-top: 3rem;
     
    }
}